<!------------------------------------------------------
 ¦ 新增弹窗
 ¦ 
 ¦ Author: 戴家浩
 ¦ Date: 2022-12-05 09:40:18
 ¦ Version: 1.0
 ¦ FilePath: src/components/dialog.vue
 ¦----------------------------------------------------->
<template>
  <div class="add">
    <el-dialog
      v-model="dialogShow"
      :show-close="false"
      :close-on-click-modal="false"
      :width="width"
      :destroy-on-close="true"
    >
      <template #header>
        <div class="header">
          <h2 class="title">{{ title }}</h2>

          <el-icon class="closed" @click="onClose">
            <Close />
          </el-icon>
        </div>
      </template>
      <!-- #region 内容 -->
      <slot name="content"></slot>
      <!-- #endregion -->

      <!-- #region 底部操作栏 -->
      <template v-if="$slots.footer" #footer>
        <div class="operator-wrapper">
          <slot name="footer" />
        </div>
      </template>
      <!-- #endregion -->
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  /**
   * 控制显示
   */
  dialogVisible: {
    type: Boolean,
    default: false
  },
  /**
   * 标题
   */
  title: {
    type: String,
    default: ''
  },
  /**
   * width
   */
  width: {
    type: Number,
    default: 560
  }
})

const emits = defineEmits(['update:dialogVisible', 'close'])
/**
 * 弹窗显示
 */
const dialogShow = computed(() => props.dialogVisible)

/**
 * 关闭
 */
const onClose = () => {
  emits('close')
}
</script>
<style lang="less" scoped>
:deep(.el-dialog) {
  background: #fff;
  border-radius: 4px;
  padding: 16px 24px 24px;
  margin: 0px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
    height: 64px;
    border-bottom: 1px solid #ccc;
    .header {
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: 700;
        color: #333333;
      }
      .closed {
        width: 18px;
        height: 18px;
      }
    }
  }
  .el-dialog__body {
    padding: 0px;
  }
  .el-dialog__footer {
    padding: 0px;
  }
}
</style>
