/*------------------------------------------------------
¦ 首页
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-14 12:41:50
¦ Version: 1.0
¦ FilePath: src/main1.js
¦------------------------------------------------------*/

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/styles/index.less'
import * as moment from 'moment'
import { useToken } from '@/composables/user'
import httpRequest from '@/utils/httpRequest'
const app = createApp(App)

// http 请求头token处理
const token = useToken()
httpRequest.setToken(() => token?.value, 'wxToken')
httpRequest.addInterceptor('RESPONSE', response => {
  const {
    status,
    data: { code, msg, message }
  } = response

  // http status 非200
  if (status.toString() !== '200') {
    throw Error(message)
  } else {
    // 如果没有code, 则直接return（可能是下载，可能接口地址写出错）
    if (typeof code === 'undefined' || code === null) {
      return response
    }

    // token验证失败
    if (code?.toString() === '524') {
      store.commit('user/UPDATE_TOKEN', null)
      router.push({ path: '/login' })
    }

    // 接口返回错误
    if (code?.toString() !== '200') {
      throw new Error(msg)
    }
  }
  return response
})

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

/**
 * 格式化时间
 */
const format = shijianchuo => {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo)
  var y = time.getFullYear()
  var m = time.getMonth() + 1
  var d = time.getDate()
  var h = time.getHours()
  var mm = time.getMinutes()
  var s = time.getSeconds()
  return (
    y +
    '-' +
    formatNumber(m) +
    '-' +
    formatNumber(d) +
    ' ' +
    formatNumber(h) +
    ':' +
    formatNumber(mm) +
    ':' +
    formatNumber(s)
  )
}
const formatNumber = m => {
  return m < 10 ? '0' + m : m
}

app.config.globalProperties.$format = format

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}

// 其他库挂载到vue上
app.config.globalProperties.$moment = moment

// 加载element图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, { locale: zhCn }).use(store).use(router).mount('#app')
