/*------------------------------------------------------
¦ 用户公用操作封装
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-17 17:20:39
¦ Version: 1.0
¦ FilePath: src/composables/user.js
¦------------------------------------------------------*/

import store from '@/store'
import { computed } from 'vue'

/**
 * 用户token
 * @returns {Ref<string>}
 */
export function useToken() {
  const { user } = store.state
  return computed(() => {
    if (!user || !user.token) {
      return null
    }
    return user.token
  })
}

/**
 * 用户权限
 * @returns {Refs<Object[]>}
 */
export function usePermission() {
  const { user } = store.state
  return computed(() => {
    if (!user || !user.userPermission) {
      return []
    }
    return user.userPermission
  })
}

/**
 * 用户信息
 * @returns {<Refs<Object>}
 */
export function useUserInfo() {
  const { user } = store.state
  return computed(() => {
    if (!user || !user.userInfo) {
      return null
    }
    return user.userInfo
  })
}

export function userMenu() {
  const { user } = store.state
  return computed(() => {
    if (!user || !user.userMenus) {
      return null
    }
    return user.userMenus
  })
}
