/*------------------------------------------------------
¦ 告警信息Socket
¦ 
¦ Author: 大风
¦ Date: 2023-03-02 11:47:42
¦ Version: 1.0
¦ FilePath: src/views/homePage/useWarnSocket.js
¦------------------------------------------------------*/

import { reactive, toRefs } from 'vue'
import config from '@/config'
import store from '@/store'

function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return unescape(r[2])
  }
  return null
}

const deviceNo = getQueryString('gateway')
if (deviceNo) {
  config.deviceNo = deviceNo
}

let binded = false

export function playWarnAudio() {
  const audio = document.getElementById('warn-audio')
  audio.currentTime = 0
  audio.play()
}

export function stopWarnAudio() {
  const audio = document.getElementById('warn-audio')
  audio.currentTime = 0
  audio.pause()
}

export function useWarnSocket() {
  const { id } = store.state.user.userInfo
  let socket = new WebSocket(
    `wss://admin.omjsmart.com/wss/api/ws/${config.deviceNo}/${id}`
  )
  socket.addEventListener('open', event => {
    console.log('websocket connection .. [event]:', event)
  })
  const state = reactive({
    changeTime: null,
    notify: null
  })

  !binded &&
    (binded = true) &&
    socket.addEventListener('message', message => {
      console.log('获取到警报', message)
      let source = null
      try {
        source = JSON.parse(message.data)
      } catch {
        return
      }
      const data = source
      data.value_string = `${data.name}告警`
      store.commit('app/UPDATE_WARN_INFO', data)
      state.notify = data
      store.commit('app/UPDATE_WARN_DIALOG_ACTIVE', true)
      playWarnAudio()
      state.changeTime = new Date()
    })

  return toRefs(state)
}
