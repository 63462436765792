<template>
  <div class="change-password">
    <Dialog v-bind="$attrs" @close="handleClose">
      <template #content>
        <el-form
          :model="deviceForm"
          label-width="108px"
          style="margin: 8px 0px 0px 0px"
        >
          <el-form-item label="原密码">
            <el-input v-model="deviceForm.oldPwd" />
          </el-form-item>
          <el-form-item label="新密码">
            <el-input v-model="deviceForm.password" />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" class="sure" @click="onUpdatePassword"
          >确 定</el-button
        >
      </template>
    </Dialog>
  </div>
</template>
<script setup>
import { ref, defineEmits } from 'vue'
import Dialog from '@/components/Dialog'
import { updatePassword } from '@/api/login'
import { ElMessage } from 'element-plus'
import store from '@/store'

const deviceForm = ref({
  id: '',
  roleList: [],
  username: '',
  oldPwd: '',
  password: ''
})

const emits = defineEmits(['update:dialogVisible'])

const handleClose = () => {
  emits('update:dialogVisible', false)
  onReset()
}

const onUpdatePassword = async () => {
  try {
    let { id, roleList, username } = store.state.user.userInfo
    deviceForm.value.id = id
    deviceForm.value.roleList = roleList
    deviceForm.value.username = username
    await updatePassword(deviceForm.value)
    onReset()
    ElMessage({
      message: '修改密码成功',
      type: 'success'
    })
  } catch (e) {
    ElMessage({
      message: e,
      type: 'error'
    })
  }

  emits('update:dialogVisible', false)
}

const onReset = () => {
  deviceForm.value = {}
}
</script>

<style lang="less" scoped>
.cancel {
  width: 96px;
  height: 40px;
  font-size: 14px;
  color: #666;
  border-color: #ccc;
  background-color: #fff;
}

.sure {
  width: 96px;
  height: 40px;
  font-size: 14px;
  background-color: #4d8930;
  color: #fff;
  border-color: transparent;
}
:deep(.el-input) {
  height: 40px;
}
:deep(.el-form-item) {
  margin-top: 15px;
}
:deep(.el-form-item__label) {
  height: 40px;
  line-height: 40px;
}
</style>
