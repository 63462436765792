/*------------------------------------------------------
¦ 应用相关状态
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-15 14:42:03
¦ Version: 1.0
¦ FilePath: src/store/modules/app.js
¦------------------------------------------------------*/

const state = {
  /**
   * 用户token
   * @type {string|null}
   */
  token: null,

  /**
   * 用户菜单
   * @type {Object[]}
   */
  userMenus: [],

  /**
   * 用户信息
   * @type {Object<any>}
   */
  userInfo: null,

  /**
   * 用户权限
   */
  userPermission: []
}

const mutations = {
  /**
   * 更新用户token
   */
  UPDATE_TOKEN(state, payload) {
    state.token = payload
  },

  /**
   * 更新用户菜单
   */
  UPDATE_USER_MENUS(state, payload) {
    state.userMenus = payload
  },

  /**
   * 更新用户信息
   */
  UPDATE_USER_INFO(state, payload) {
    state.userInfo = payload
  },

  /**
   * 更新用户权限信息
   */
  UPDATE_USER_PERMISSION(state, payload) {
    state.userPermission = payload
  }
}

/**
 * 用户相关状态
 * @returns {Object<any>}
 */
export default {
  namespaced: true,
  state,
  mutations
}
