/*------------------------------------------------------
¦ http请求封装
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-14 12:42:49
¦ Version: 1.0
¦ FilePath: src/utils/httpRequest.js
¦------------------------------------------------------*/

import axios from 'axios'
import appConfig from '@/config'

/**
 * http请求类
 */
export class HttpRequest {
  /**
   * axios
   */
  #axios

  /**
   * 实例化HttpRequest
   * @param {object} config
   */
  constructor(
    config = {
      baseURL: appConfig.apiBaseUrl,
      timeout: 50000,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ) {
    this.#axios = axios.create({
      ...config
    })
  }

  /**
   * 发起请求
   * @param {string<'POST'|'GET'|'PUT'|'DELETE'>} method
   * @param {string} url
   * @param {object} data
   * @param {object} config
   * @returns {Promise<any>}
   */
  request(method, url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      const options = {
        method,
        url,
        config
      }
      if (method.toLocaleUpperCase() === 'GET') {
        options.params = data
      } else {
        options.data = data
      }
      if (config.responseType) {
        options.responseType = config.responseType
      }
      this.#axios(options)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  /**
   * HTTP GET METHOD
   * @param {string} url
   * @param {object} params
   * @param {object} config
   * @return {Promise<any>}
   */
  get(url, params = {}, config = {}) {
    return this.request('GET', url, params, config)
  }

  /**
   * HTTP POST METHOD
   * @param {string} url
   * @param {object} data
   * @param {object} config
   * @return {Promise<any>}
   */
  post(url, data = {}, config = {}) {
    return this.request('POST', url, data, config)
  }

  /**
   * HTTP DELETE METHOD
   * @param {string} url
   * @param {string} data
   * @param {string} config
   * @returns {Promise<any>}
   */
  delete(url, data = {}, config = {}) {
    return this.request('DELETE', url, data, config)
  }

  /**
   * HTTP PUT METHOD
   * @param {string} url
   * @param {string} data
   * @param {string} config
   * @returns {Promise<any>}
   */
  put(url, data = {}, config = {}) {
    return this.request('PUT', url, data, config)
  }

  /**
   * 添加拦截器
   * @param {string<'REQUEST'|'RESPONSE'>} type
   * @param {function} fn
   */
  addInterceptor(type = 'REQUEST', fn = () => {}) {
    const typeUpperCase = type.toLocaleUpperCase()
    if (typeUpperCase === 'REQUEST') {
      this.#axios.interceptors.request.use(fn, err => Promise.reject(err))
    } else if (typeUpperCase === 'RESPONSE') {
      this.#axios.interceptors.response.use(fn, err => fn(err.response))
    } else {
      throw new Error('无效的拦截器类型')
    }
  }

  /**
   * 设置token
   * @param {string|function} token
   * @param {string} keyName
   */
  setToken(token, keyName = 'token') {
    this.addInterceptor('REQUEST', config => {
      const tokenVal = typeof token === 'function' ? token(config) : token
      /// 如果token为false, 不要设置 (有些后端在登录接口禁止将token带入http headers)
      if (tokenVal === false) {
        return config
      }
      config.headers[keyName] = tokenVal
      return config
    })
  }
}

export default new HttpRequest()
