/*------------------------------------------------------
¦ 应用相关状态
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-15 14:41:22
¦ Version: 1.0
¦ FilePath: src/store/modules/app.js
¦------------------------------------------------------*/

import appConfig from '@/config'

const state = {
  /**
   * 菜单类型
   * @type {number}
   */
  menuType: appConfig.appMenuType,

  /**
   * 激活的顶部菜单(这里使用菜单索引)
   * @type {number}
   */
  topBarMenuActive: 0,

  /**
   * 右边菜单(索引)
   * @type {string}
   */
  leftMenuActive: '1',

  /**
   * 告警弹窗
   */
  warnVisible: false,

  /**
   * 告警信息
   */
  warnInfo: {}
}

const mutations = {
  /**
   * 更新激活的顶部菜单
   */
  UPDATE_TOP_BAR_MENU_ACTIVE(state, payload) {
    state.topBarMenuActive = payload
  },

  /**
   * 更新右边菜单栏选中
   */
  UPDATE_LEFT_MENU_ACTIVE(state, index) {
    state.leftMenuActive = index
  },

  /**
   * 更新告警弹窗
   */
  UPDATE_WARN_DIALOG_ACTIVE(state, isOpen) {
    console.log(isOpen)
    state.warnVisible = isOpen
  },

  /**
   * 更新告警弹窗
   */
  UPDATE_WARN_INFO(state, info) {
    state.warnInfo = info
  }
}

/**
 * 默认输出
 * @returns {Object<any>}
 */
export default {
  namespaced: true,
  state,
  mutations
}
