import { createRouter } from 'vue-router'
import { ref, isRef } from 'vue'
import { useToken } from '@/composables/user'
import Layout from '@/components/Layout'
export default {
  // 路由
  routes: null,
  // 路由实例
  router: null,
  createRoutes(options) {
    if (!options.routes) {
      options.routes = []
    }
    if (!options.menuList) {
      options.menuList = ref([])
    }
    options.routes.push(this.getMatchAllConfig())
    this.routes = options.routes
    this.router = createRouter(options)
    this.createDynamicRoutes(options.menuList)
    return this.router
  },
  /**
   * 获取匹配所有路径路由配置
   * @returns {Object}
   */
  getMatchAllConfig() {
    return {
      path: '/:pathMatch(.*)*',
      name: 'matchAll'
    }
  },
  createDynamicRoutes(menu) {
    this.router.beforeEach((to, from, next) => {
      if (to.name === 'matchAll') {
        this.addDynamicRoutes(menu)
        console.log(this.router.getRoutes())
        next({ path: to.fullPath, replace: true })
      } else {
        const token = useToken()
        if (token.value && to.path === '/') {
          next({ path: menu.value[0].path, replace: true })
        } else {
          next()
        }
      }
    })
  },
  //添加动态路由
  addDynamicRoutes(menu) {
    if (!isRef(menu)) {
      throw new Error('无效数据 不是Ref创建的响应式对象')
    }
    menu.value.forEach(item => {
      const getRoutes = this.analysisRoutes(item)
      if (getRoutes) {
        this.router.addRoute(getRoutes)
      }
    })
  },
  // 解析动态路由
  analysisRoutes(menuItem) {
    const pathArr = menuItem.path.split('/')
    const route = {
      path: `/${pathArr[1]}`,
      component: Layout,
      children: [
        {
          name: menuItem.path,
          path: pathArr[2],
          component: () => import(`@/views${menuItem.component}`)
        }
      ],
      meta: {}
    }
    return route
  }
}
