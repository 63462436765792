import httpRequest from '@/utils/httpRequest'
/**
 * 登录
 * @param {Object} params
 * @returns
 */
export const login = params => {
  return httpRequest.post('/api/sso/v1/login', params)
}

/**
 * 获取用户信息
 * @param {Object} params
 * @returns
 */
export const getUserInfo = () => {
  return httpRequest.post('/api/sso/queryUserDetail')
}

/**
 * 获取用户信息
 * @param {Object} params
 * @returns
 */
export const updateStatus = params => {
  return httpRequest.post('/api/admin/updateStatus', params)
}

/**
 * 修改密码
 * @param {Object} params
 * @returns
 */
export const updatePassword = params => {
  return httpRequest.post('/api/sso/updateUser', params)
}

/**
 * 重置密码
 * @param {Object} params
 * @returns
 */
export const resetPassword = params => {
  return httpRequest.post('/api/sso/resetPassword', params)
}
