<!------------------------------------------------------
 ¦ 头部栏
 ¦ 
 ¦ Author: 大风
 ¦ Email: 1236192@qq.com
 ¦ Date: 2023-04-19 09:40:47
 ¦ Version: 1.0
 ¦ FilePath: src/components/Layout/components/TopBar.vue
 ¦----------------------------------------------------->

<template>
  <div class="layout-top-bar">
    <!-- 左边 log -->
    <div class="left">
      <div class="logo-wrapper">
        <img src="@/assets/logo.jpg" alt="" />
      </div>
    </div>

    <!-- 右边用户信息 -->
    <div class="right">
      <div class="user-info">
        <div class="avatar-wrapper"></div>
        <div class="welcome-text">{{ state.welcomeText }}</div>
        <div class="user-role">{{ state.role }}</div>
        <el-icon
          @click="loginOutShow = !loginOutShow"
          :class="loginOutShow ? 'turn' : ''"
        >
          <arrow-down />
        </el-icon>
      </div>
    </div>

    <!-- 下拉显示 -->
    <div class="pull-down" v-if="loginOutShow">
      <div @click="onChangePassword">修改密码</div>
      <div @click="onLoginOut">退出登录</div>
    </div>
  </div>
  <!-- 修改密码弹窗 -->
  <ChangePassword title="修改密码" v-model:dialogVisible="dialogVisible" />
</template>

<script setup>
import { reactive, ref } from 'vue'
import store from '@/store'
import { ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
import { updateStatus } from '@/api/login'
import ChangePassword from './ChangePassword.vue'

const router = useRouter()

/**
 * 用户信息
 */
const { userInfo } = store.state.user
console.log(userInfo)

/**
 * 退出展示
 */
let loginOutShow = ref(false)

/**
 * 修改密码弹窗
 */
let dialogVisible = ref(false)

/**
 * 组件内部数据
 */
const state = reactive({
  welcomeText: '欢迎您！' + userInfo.nickname,
  role: userInfo.roleDetailList[0].roleName
})

/**
 * 修改密码
 */
const onChangePassword = () => {
  dialogVisible.value = true
  loginOutShow.value = false
}

const onLoginOut = () => {
  try {
    ElMessageBox.confirm('确定要退出登录吗？', '提示', {
      confirmButtonText: '是',
      cancelButtonText: '否',
      type: 'warning'
    }).then(async () => {
      //更新用户登录状态
      let params = {
        onlineStatus: 0,
        userId: store.state.user.userInfo.id
      }
      await updateStatus(params)
      store.commit('user/UPDATE_USER_INFO', null)
      store.commit('user/UPDATE_TOKEN', null)
      router.push('/login')
    })
  } catch {
    return
  }
}
</script>

<style lang="less" scoped>
.layout-top-bar {
  width: 100%;
  height: 72px;
  // border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  position: relative;

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 172.5px;
      height: 40px;
      margin-left: 24px;
    }
  }

  .avatar-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ddd;
    margin-right: 8px;
  }

  .user-info {
    display: flex;
    align-items: center;
    padding-right: 18px;
    font-size: 16px;
    color: #3d3d3d;

    .turn {
      transform: rotate(180deg);
    }
  }

  .title-wrapper {
    font-size: 28px;
    font-weight: bold;
  }

  .welcome-text {
    margin-right: 8px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #3d3d3d;
    line-height: 28px;
  }

  .user-role {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 80px;
    padding: 0px 10px;
    height: 24px;
    background: rgba(77, 137, 48, 0.16);
    border-radius: 12px 12px 12px 12px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4d8930;
    line-height: 24px;
    margin-right: 16px;
  }

  .pull-down {
    width: 200px;
    height: 80px;
    background: #fff;
    position: absolute;
    right: 10px;
    top: 60px;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666;
    z-index: 10000;
    box-shadow: 1px 1px 1px 1px #4d8930;
    div {
      height: 40px;
    }
  }
}
</style>
