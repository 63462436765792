/*------------------------------------------------------
¦ 路由
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-14 12:42:49
¦ Version: 1.0
¦ FilePath: src/router/index.js
¦------------------------------------------------------*/

import { createWebHashHistory } from 'vue-router'
import { useToken } from '@/composables/user'
import config from '@/config'
import Layout from '@/components/Layout'
import dynamicRoutes from '@/utils/dynamicRoutes'
import { userMenu } from '@/composables/user'

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => Layout
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  }
]

const menuList = userMenu()

const router = dynamicRoutes.createRoutes({
  history: createWebHashHistory(),
  routes,
  menuList
})

router.beforeEach((to, from, next) => {
  const token = useToken()

  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title
  }

  if (!token.value && !config.authWhiteList.includes(to.fullPath)) {
    next({ fullPath: '/login', path: '/login', replace: false })
  } else {
    next()
  }
})

/**
 * 路由
 * @returns {Object<any>}
 */
export default router
