/*------------------------------------------------------
¦ App 配置文件
¦ 
¦ Author: 大风
¦ Email: 1236192@qq.com
¦ Date: 2022-11-14 18:20:45
¦ Version: 1.0
¦ FilePath: src/config/index.js
¦------------------------------------------------------*/

/**
 * App 配置文件
 */
export default {
  /**
   * api接口基础路径
   * @type {string}
   */
  apiBaseUrl: '',

  /**
   * 验证白名单
   * @type {Object[<string>]
   */
  authWhiteList: ['/login'],
  appMenuType: 2,
  /**
   * 静态文件基础路径
   * @type {string}
   */
  fileBasePath: '/files',

  deviceNo: '861bf4c7c684d52e'
}
