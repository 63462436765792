<template>
  <div class="warn">
    <Dialog v-bind="$attrs" @close="handleClose">
      <template #content>
        <div class="content">
          <div class="content-item">
            <span>告警时间</span>
            <span>{{ $format(warnInfo.createTimestamp) }}</span>
          </div>
          <div class="content-item">
            <span>告警级别</span>
            <span>{{
              warnInfo.warnType === 1
                ? '高级'
                : warnInfo.warnType === 2
                ? '中级'
                : '低级'
            }}</span>
          </div>
          <div class="content-item">
            <span>老人姓名</span>
            <span>{{ warnInfo?.userName }}</span>
          </div>
          <div class="content-item">
            <span>告警原因</span>
            <span>{{ warnInfo.deviceName }}</span>
          </div>
          <div class="content-item">
            <span>告警地址</span>
            <span>{{ warnInfo.addr }}</span>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <el-button @click="onHandle">去处理</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import Dialog from '../../Dialog/index.vue'
import { useRouter } from 'vue-router'
import { defineEmits, watch, ref } from 'vue'
import store from '@/store'
import { stopWarnAudio } from '../../useWarnSocket.js'
import { ElMessage } from 'element-plus'

const emits = defineEmits(['update:dialogVisible'])

const router = useRouter()

let warnInfo = ref({})
watch(
  () => store.state.app.warnInfo,
  () => {
    warnInfo.value = store.state.app.warnInfo
    console.log(warnInfo.value)
  },
  { deep: true }
)

const onHandle = () => {
  //判断是否有权限
  let isAllow = false
  store.state.user.userInfo?.menusList.forEach(item => {
    if (item.path == '/order/warn') {
      isAllow = true
    }
  })
  if (isAllow) {
    router.push({ path: '/order/warn' })
    store.commit('app/UPDATE_WARN_DIALOG_ACTIVE', false)
    stopWarnAudio()
  } else {
    ElMessage({
      message: '暂无权限',
      type: 'error'
    })
  }
}

const handleClose = () => {
  emits('update:dialogVisible', false)
  store.commit('app/UPDATE_WARN_DIALOG_ACTIVE', false)
  stopWarnAudio()
}
</script>
<style lang="less" scoped>
.warn {
  z-index: 10000000;
  .content {
    &-item {
      height: 56px;
      line-height: 56px;
      span {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      span:last-child {
        margin-left: 16px;
        color: #333333;
      }
    }
  }
  .footer {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    :deep(.el-button) {
      width: 240px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #df0e00;
      border-radius: 4px;
      color: #fff;
    }
  }
}
</style>
