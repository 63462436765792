<!------------------------------------------------------
 ¦ App入口
 ¦
 ¦ Author: 大风
 ¦ Email: 1236192@qq.com
 ¦ Date: 2022-11-14 12:41:15
 ¦ Version: 1.0
 ¦ FilePath: src/App.vue
 ¦----------------------------------------------------->

<template>
  <router-view />
</template>

<script setup></script>
