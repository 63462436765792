<!------------------------------------------------------
 ¦ 左侧栏
 ¦ 
 ¦ Author: 大风
 ¦ Email: 1236192@qq.com
 ¦ Date: 2023-04-19 09:40:31
 ¦ Version: 1.0
 ¦ FilePath: src/components/Layout/components/LeftSideBar.vue
 ¦----------------------------------------------------->

<template>
  <div class="layout-left-side-bar">
    <el-menu :default-active="onMenuActive($route.path)">
      <template v-for="menuItem in menu" :key="menuItem.id">
        <el-menu-item
          v-if="menuItem.hide === 0"
          :index="menuItem.path"
          @click="onNavigationTo(menuItem.path)"
        >
          <div class="menu-content">
            <div>{{ menuItem.name }}</div>
            <div class="menu-number" v-if="menuItem.number">
              {{ menuItem.number }}
            </div>
          </div>
        </el-menu-item>
      </template>
      <el-menu-item @click="toDriving">
        <div class="menu-content">
          <div>数据驾驶舱</div>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import store from '@/store'
import { useToken } from '@/composables/user'

const router = useRouter()

/**
 * 左侧菜单栏
 * @returns {Object[]}
 */
function useLeftSideMenus() {
  const { user, app } = store.state
  return computed(() => {
    if (app.menuType === 2) {
      // 如果只显示左侧菜单，则返回用户菜单列表
      return user.userMenus
    } else {
      // 否则返回空集合
      return []
    }
  })
}

const menu = useLeftSideMenus()
console.log(menu, '张三')

const onMenuActive = menuActive => {
  // 跳转设备列表 锁定到 网关
  if (menuActive === '/gateway/device') {
    return '/gateway/index'
  }
  if (menuActive === '/project/operate') {
    return '/project/index'
  }
  return menuActive
}

let token = useToken()

console.log(token.value)

const toDriving = () => {
  window.open('https://yl.omjsmart.com/#/dashbord', token.value)
  // window.open('http://192.168.1.138:8081/#/dashbord', token.value)
}

/**
 * 菜单
 */
// const menus = [
//   {
//     name: '超管列表',
//     path: '/supertube',
//     id: '8',
//     number: ''
//   },
//   {
//     name: '管理员列表',
//     path: '/manager',
//     id: '1',
//     number: ''
//   },
//   {
//     name: '角色配置',
//     path: '/role',
//     id: '2',
//     number: ''
//   },
//   {
//     name: '老人列表',
//     path: '/old',
//     id: '6',
//     number: ''
//   },
//   {
//     name: '工作人员列表',
//     path: '/staff',
//     id: '7',
//     number: ''
//   },
//   {
//     name: '告警处置',
//     path: '/warn',
//     id: '3',
//     number: '2'
//   },
//   {
//     name: '全部告警列表',
//     path: '/allWarn',
//     id: '4',
//     number: ''
//   },
//   {
//     name: '设备管理',
//     path: '/gateway',
//     id: '5',
//     number: ''
//   },
//   {
//     name: '项目列表',
//     path: '/project',
//     id: '5',
//     number: ''
//   }
// ]

/**
 * 跳转路由
 * @type {string} path path
 */
const onNavigationTo = path => {
  router.push(path)
}
</script>

<style lang="less" scoped>
.layout-left-side-bar {
  width: 272px;
  height: 100%;

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 9px;
  }

  :deep(.el-menu) {
    border: 0px;
    padding-top: 56px;

    .el-menu-item {
      height: 56px;
      padding: 4px 36px;

      .menu-content {
        width: 100%;
        padding-left: 40px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        display: flex;
        align-items: center;

        .menu-number {
          margin-left: 17px;
          width: 24px;
          height: 24px;
          background: #df0e00;
          border-radius: 50%;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
        }
      }
    }

    .el-menu-item:hover {
      background: #fff;
    }

    .el-menu-item.is-active {
      .menu-content {
        width: 100%;
        height: 100%;
        padding-left: 40px;
        background: rgba(77, 137, 48, 0.16);
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #4d8930;
        line-height: 48px;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
}
</style>
