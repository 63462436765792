<!------------------------------------------------------
 ¦ 界面布局
 ¦ 
 ¦ Author: 大风
 ¦ Email: 1236192@qq.com
 ¦ Date: 2023-04-19 09:39:33
 ¦ Version: 1.0
 ¦ FilePath: src/components/Layout/index.vue
 ¦----------------------------------------------------->

<template>
  <div class="layout-wrapper">
    <TopBar />
    <div class="layout-center">
      <LeftSideBar />
      <div class="layout-content">
        <router-view v-slot="{ Component }">
          <keep-alive :exclude="excludes">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>

    <!-- 弹窗 -->
    <WarnDialog v-model:dialogVisible="warnVisible" title="告警信息" />
  </div>
</template>

<script setup>
import LeftSideBar from './components/LeftSideBar'
import TopBar from './components/TopBar'
import { watch, ref } from 'vue'
import { useWarnSocket, stopWarnAudio } from '../useWarnSocket.js'
import WarnDialog from './components/WarnDialig'
import store from '@/store'

let warnVisible = ref(false)
watch(
  () => store.state.app.warnVisible,
  () => {
    console.log('出弹窗')
    warnVisible.value = store.state.app.warnVisible
  }
)

window.addEventListener('beforeunload', () => {
  store.commit('app/UPDATE_WARN_DIALOG_ACTIVE', false)
  stopWarnAudio()
})

// watch(
//   () => store.state.user.userInfo,
//   () => {
//     console.log(store.state.user.userInfo)
//   }
// )

useWarnSocket()

/**
 * 被keep-alive排除的组件名集合
 * @type {Object[string]}
 */
const excludes = []
</script>

<script>
export default {
  name: 'Layout'
}
</script>

<style lang="less" scoped>
.layout-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.layout-center {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;
}

.layout-content {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
</style>
